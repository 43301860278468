import React from "react";
import { Box, Typography, Grid, Card } from "@mui/material";
import SideNav from "../../components/sideNav";
import Table from "./Table";


function CallLogs() {
 
  return (
    <Box
      sx={{
        pt: {
          xs: "50px",
          md: "50px",
        },
        height: "100%",
       
        bgcolor: "#F4F6FE",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <SideNav />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Typography
            sx={{
              fontSize: "1.8rem",
              fontWeight: 900,
              color: "#424242",
            }}
            gutterBottom
          >
            Call Log
          </Typography>
          <Box
            sx={{
              height: "0.5px",
              backgroundColor: "#c9c9c9",
            }}
          ></Box>
          <Box
            sx={{
              mt: "30px",
            }}
          >
            <Table />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default CallLogs;
