import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Grid,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const pricingPlans = [
  {
    title: "Demo",
    price: "Free",
    hour: "12",
    features: [
      "Super user account",
      "Unlimited events",
      "Registration Form",
      "Email announcements",
      "Integrate webhooks",
      "Sales using mobile app",
    ],
  },
  {
    title: "Business",
    price: 10000,
    hour: "12",
    features: [
      "All Team features",
      "Advanced analytics",
      "Priority support",
      "Custom branding",
      "API access",
      "Dedicated account manager",
    ],
  },
  {
    title: "Enterprise",
    price: 20000,
    hour: "24",
    features: [
      "All Business features",
      "Unlimited users",
      "Tailored solutions",
      "Enhanced security",
      "Onboarding support",
      "Personalized training",
    ],
  },
];
export default function TeamPricingCard() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [cardDetails, setCardDetails] = useState([]);
  console.log(cardDetails, "cardDetails");

  useEffect(() => {
    getPricing();
  }, []);

  const getPricing = async () => {
    const res = await axios.get(
      `https://uae.justtawk.com/api/Payment/getSubscriptionPlan`,
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    setCardDetails(res?.data?.message);
  };

  return (
    <Box sx={{ p: 3, mt: 2, width: "100%" }}>
      <Grid container spacing={3} justifyContent="center">
        {cardDetails.map((e, i) => (
          <Grid item xs={12} sm={6} md={3.5}>
            <Card
              sx={{
                maxWidth: 400,
                width: "100%",
                position: "relative",
                overflow: "visible",
                transition: "transform 0.3s ease-in-out", // Add a smooth transition
                "&:hover": {
                  transform: "scale(1.05)", // Scale up by 5% when hovered
                },
              }}
            >
              <CardContent sx={{ p: 5 }}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    textAlign: "center",
                    fontWeight: 500,
                    mb: 2,
                  }}
                >
                  {e.title}
                </Typography>

                <Box sx={{ textAlign: "center", mb: 1 }}>
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{
                      // fontWeight: "bold",
                      display: "inline-flex",
                      alignItems: "baseline",
                      color: "lightgray",
                      textDecoration: "line-through", // Correct textDecoration value
                    }}
                  >
                    ₹{Math.floor(e.price)}
                  </Typography>
                  <Typography
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      ml: 1,
                      color: "lightgray",
                    }}
                  >
                    /mo
                  </Typography>
                </Box>
                <Box sx={{ textAlign: "center", mb: 1 }}>
                  <Typography
                    variant="h3"
                    component="div"
                    sx={{
                      fontWeight: "bold",
                      display: "inline-flex",
                      alignItems: "baseline",
                    }}
                  >
                    ₹{Math.floor(e.offer_price)}
                  </Typography>
                  <Typography
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      ml: 1,
                      color: "text.secondary",
                    }}
                  >
                    /mo
                  </Typography>
                </Box>
                <Box sx={{ textAlign: "center", mb: 3 }}>
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{
                      // fontWeight: "bold",
                      display: "inline-flex",
                      alignItems: "baseline",
                    }}
                  >
                    {e.window}
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    mb: 2,
                    py: 1.5,
                    textTransform: "none",
                    borderRadius: 2,
                    backgroundColor: "#1a237e",
                    "&:hover": {
                      backgroundColor: "#0d47a1",
                    },
                  }}
                  onClick={() => navigate(`/bill-details/${e?.sid}`)}
                >
                  Get started
                </Button>

                <List sx={{ mt: 1 }}>
                  {pricingPlans[1].features.map((feature, idx) => (
                    <ListItem key={idx} sx={{ px: 0, py: 1 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CheckIcon sx={{ color: "#1a237e" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={feature}
                        sx={{
                          "& .MuiListItemText-primary": {
                            fontSize: "0.95rem",
                            color: "text.secondary",
                          },
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
