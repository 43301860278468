import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import SideNav from "../../components/sideNav";
import PricingCard from "./PricingCard";

function PricingPlans() {
  return (
    <Box
      sx={{
        display: "flex",
        pt: {
          xs: "80px",
          md: "100px",
        },
        bgcolor: "#F4F6FE",
      }}
    >
      <SideNav />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: {
            xs: 0,
            md: 3,
          },
          pt: "50px",
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: "1.8rem",
              md: "3rem",
            },
            p: "0 0px",
            fontWeight: 900,
            color: "Black",
            textAlign: "center",
            lineHeight: {
              xs: "35px",
              md: "50px",
            },
            fontFamily: "sans-serif",
          }}
          gutterBottom
        >
          Make the wise decision <br /> for your business
        </Typography>
        <Typography
          sx={{
            fontSize: "1.2rem",
            color: "Black",
            textAlign: "center",
            // lineHeight: "50px",
            // fontFamily: "sans-serif",
            p: "0 30px",
          }}
          gutterBottom
        >
          Choose from our affordable packages
        </Typography>

        <PricingCard />
      </Box>
    </Box>
  );
}

export default PricingPlans;
