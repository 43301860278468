import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoginBg from "../../../src/assets/LoginBg.jpeg";
import { useNavigate } from "react-router-dom";
import JusttawkBg from "../../../src/assets/justtawk-bg.png";
import JusttawkLogo from "../../../src/assets/Justtawk-01.png";

import axios from "axios";

export default function Otp() {
  const navigate = useNavigate();
  const uid = localStorage.getItem("uid");
  const isNew = localStorage.getItem("isNew");
  const mobile = localStorage.getItem("mobileNo");
  const medium = localStorage.getItem("medium");
  const source = localStorage.getItem("source");
  const campaign = localStorage.getItem("campaign");

  const [isResending, setIsResending] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setIsButtonDisabled(false);
    }
  }, [resendTimer]);

  const verifyOtp = async (otp) => {
    try {
      const res = await axios.post(
        "https://uae.justtawk.com/index.php/api/User/otp",
        otp
      );
      console.log(res?.data?.status);
      if (res?.data?.status === true) {
        if (isNew == 2) {
          navigate("/dashboard");
        } else {
          navigate("/form");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Function to handle OTP resend
  const handleResendOtp = async () => {
    try {
      setIsResending(true);
      setResendTimer(60);
      const resendResponse = await axios.post(
        "https://uae.justtawk.com/index.php/api/User/register",
        { mobile }
      );
      if (resendResponse?.data?.status === true) {
        setIsButtonDisabled(true);
        let countdown = 60;
        const timer = setInterval(() => {
          setResendTimer(countdown);
          countdown--;
          if (countdown <= 0) {
            clearInterval(timer);
            setIsResending(false);
            setIsButtonDisabled(false);
          }
        }, 1000);
      } else {
        setIsResending(false);
        alert("Failed to resend OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setIsResending(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .required("OTP is required")
        .matches(/^\d{5}$/, "Must be a valid 5-digit OTP"),
    }),
    onSubmit: (values) => {
      const data = {
        otp: values.otp,
        userid: uid,
        medium: medium,
        source: source,
        campaign: campaign,
      };
      console.log("Form values:", values);
      verifyOtp(data);
    },
  });

  return (
    <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          sx={{
            height: {
              xs: "50vh", // Use 50vh for mobile, it ensures it's more flexible
              md: "100vh", // Full height on larger screens
            },
            backgroundImage: {
              xs: `url(${JusttawkBg})`,
              md: `url(${LoginBg})`,
            },
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) => t.palette.primary.main,
            backgroundSize: "cover", // Ensures the image covers the entire area
            backgroundPosition: "center",
            position: "relative",
            color: "white",
            display: { xs: "flex", sm: "flex" },
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: { xs: "20px", md: "100px 0" }, // Adjust padding for mobile view
            mt: {
              xs: "-100px",
              md: "0px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: {
                  xs: 400,
                  md: 900,
                },
                fontSize: {
                  xs: "1.1rem",
                  md: "1.8rem",
                },
                mt: {
                  xs: "50px",
                  md: "0px",
                },
                whiteSpace: "nowrap",
              }}
            >
              Welcome to{" "}
              <Box
                sx={{
                  display: { xs: "flex", md: "none" }, // Show image on mobile, hide on md and larger
                  justifyContent: "center",
                  alignItems: "center",
                  p: "7px 0",
                }}
              >
                <img
                  src={JusttawkLogo}
                  alt="Justtawk Logo"
                  style={{
                    height: "auto", // Keep aspect ratio intact
                    maxWidth: "100%", // Ensure the image does not exceed container width
                    width: "220px", // Set a fixed width for the image
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: { xs: "none", md: "inline" }, // Show text on md and larger
                }}
              >
                Justtawk
              </Box>
            </Typography>

            <Typography
              sx={{
                fontWeight: {
                  xs: 400,
                  md: 100,
                },
                fontSize: "1rem",
              }}
            >
              Your Gateway to Effortless Call Center.
            </Typography>
          </Box>

          {/* Bottom Text Block */}
          <Box
            sx={{
              display: {
                xs: "none",
                md: "flex",
              },
              flexDirection: "column",
              justifyContent: "center", // Center text vertically
              alignItems: "center", // Center text horizontally
              textAlign: "center", // Ensure text is centered within its Box
              mt: "auto", // Pushes the bottom block towards the bottom
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 900,
                fontSize: {
                  xs: "1rem",
                  md: "1.8rem",
                },
              }}
            >
              Seamless Collaboration
            </Typography>
            <Typography
              sx={{
                fontWeight: 100,
                fontSize: {
                  xs: "0.7rem",
                  md: "1rem",
                },
              }}
            >
              Effortlessly work together with your <br /> team in real-time.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: { xs: "0 40px", sm: "0 50px", md: "0 80px" },
            width: "100%",
            maxWidth: "500px",
            mx: "auto",
          }}
        >
          {/* Form Section */}
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  fontSize: "2rem",
                  fontWeight: 900,
                  textAlign: {
                    xs: "center",
                    md: "initial",
                  },
                  mt: {
                    xs: "30px",
                    md: "0px",
                  },
                }}
              >
                OTP Verification
              </Typography>
            </Box>
            {/* OTP Field */}
            <Typography
              sx={{
                fontSize: "0.8rem",
                color: "#26203B",
                mt: {
                  xs: 2,
                  md: 4,
                },
              }}
            >
              OTP has been sent to your number {mobile}. Please verify
            </Typography>
            <TextField
              id="otp"
              name="otp"
              type="text"
              variant="outlined"
              margin="normal"
              fullWidth
              value={formik.values.otp}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.otp && Boolean(formik.errors.otp)}
              helperText={formik.touched.otp && formik.errors.otp}
              InputProps={{
                style: {
                  height: "40px",
                  borderRadius: "8px",
                  border: "1px solid #465FF166",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                },
              }}
            />
            <Box sx={{ mt: 1 }}>
              <Typography variant="caption" color="textSecondary" gutterBottom>
                OTP Requirements
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                display="block"
              >
                • Must be a valid 5-digit OTP
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                display="block"
              >
                • Cannot contain any letters or special characters (e.g., +, -,
                or spaces)
              </Typography>
            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                mt: 3,
                mb: 2,
                bgcolor: "#465FF1",
                textTransform: "capitalize",
              }}
            >
              Verify OTP
            </Button>
            {/* Resend OTP Button */}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                // alignItems: "center",
                mt: 0,
              }}
            >
              <Box>
                {" "}
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    fontSize: "0.8rem",
                    color: "#465FF1",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/login")}
                >
                  Change number
                </Typography>
              </Box>
              <Box>
                {resendTimer > 0 ? (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ fontSize: "0.8rem", color: "#26203B" }}
                  >
                    Resend OTP in {resendTimer}s
                  </Typography>
                ) : (
                  <Button
                    onClick={handleResendOtp}
                    disabled={isButtonDisabled}
                    color="primary"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {isResending ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Resend OTP"
                    )}
                  </Button>
                )}
              </Box>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
}
