import {  Box, Button, IconButton, styled, Toolbar, Typography } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import MuiAppBar from '@mui/material/AppBar';
import Header from "../components/header";

const AppBar = styled(MuiAppBar, {
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

function Layout() {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        {/* <Header/> */}
        <Box sx={{marginTop:"60px"}}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
}

export default Layout;
