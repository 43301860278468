import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css"; // You can choose other themes
import axios from "axios";
import PlayCircleIcon from "@mui/icons-material/PlayCircle"; // Material UI Icon for play button

const ResponsiveAgGridTable = () => {
  const uid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    getTableData();
  }, []);

  const getTableData = async () => {
    try {
      const res = await axios.get(
        `https://uae.justtawk.com/index.php/api/Onboarding/CallLogs/${uid}`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Handle the response data structure (e.g., res.data.data)
      const data = res?.data?.data || [];
      setRowData(data);

      if (data.length > 0) {
        const dynamicColumnDefs = Object.keys(data[0]).map((key) => ({
          headerName: key.replace(/([A-Z])/g, " $1").toUpperCase(), // Convert camelCase to Title Case
          field: key,
          sortable: true,
          filter: true,
          resizable: true,
        }));

        // Add an audio column at the end (or wherever you want)
        dynamicColumnDefs.push({
          headerName: "Play Recording",
          field: "recording", // Ensure this field exists in your data
          cellRendererFramework: "audioRenderer", // Use a custom React component
          sortable: false,
          filter: false,
          resizable: true,
        });

        setColumnDefs(dynamicColumnDefs);
      }

      console.log("API Data:", data); // For debugging
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };

  // Custom audio renderer for the new audio column as a React component
  const AudioRenderer = (props) => {
    const { value } = props;
    if (value) {
      return (
        <span
          style={{
            cursor: "pointer",
            fontSize: "24px",
            color: "#4CAF50",
          }}
          onClick={() => playAudio(value)}
        >
          <PlayCircleIcon /> {/* Material UI Play Icon */}
        </span>
      );
    }
    return "No recording"; // Display message when no recording is available
  };

  // JavaScript function to play the audio
  const playAudio = (url) => {
    const audioElement = new Audio(url); // Create an Audio object with the URL
    audioElement.play(); // Play the audio
  };

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
    minWidth: 100,
  };

  return (
    <div style={{ width: "100%", height: "600px" }}>
      <div
        className="ag-theme-alpine"
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <AgGridReact
          columnDefs={columnDefs} // Use dynamically generated columnDefs
          rowData={rowData} // Set row data from API
          defaultColDef={defaultColDef}
          domLayout="autoHeight" // Adjusts the height automatically based on content
          pagination={true}
          paginationPageSize={10}
          frameworkComponents={{
            audioRenderer: AudioRenderer, // Register the custom audioRenderer component as a React component
          }}
        />
      </div>
    </div>
  );
};

export default ResponsiveAgGridTable;
