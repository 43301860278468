import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
} from "@mui/material";
import SideNav from "../../adminComponents/sidenav";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import { Phone, PhoneMissed } from "@mui/icons-material";

function AdminDashboard() {
  return (
    <Box
      sx={{
        display: "flex",
        p: {
          xs: "50px 0 0 0",
          md: "80px 20px",
        },
      }}
    >
      <SideNav />
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box sx={{ padding: "24px" }}>
          <Typography
            sx={{
              fontSize: {
                xs: "1rem",
                md: "2rem",
              },
              fontWeight: 550,
              p: "20px 0",
            }}
          >
            Admin Dashboard
          </Typography>
          {/* Metrics Cards */}
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  padding: "24px",
                  backgroundColor: "#6366f1",
                  color: "white",
                }}
              >
                <CardContent>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <div
                        style={{
                          padding: "8px",
                          backgroundColor: "rgba(255, 255, 255, 0.2)",
                          borderRadius: "50%",
                        }}
                      >
                        <Phone style={{ width: "24px", height: "24px" }} />
                      </div>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" sx={{ opacity: 0.8 }}>
                        Total Call
                      </Typography>
                      <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                        20
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  padding: "24px",
                  backgroundColor: "#60a5fa",
                  color: "white",
                }}
              >
                <CardContent>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    display="flex"
                  >
                    <Grid item>
                      <div
                        style={{
                          padding: "8px",
                          backgroundColor: "rgba(255, 255, 255, 0.2)",
                          borderRadius: "50%",
                        }}
                      >
                        <AddIcCallIcon
                          style={{ width: "24px", height: "24px" }}
                        />
                      </div>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" sx={{ opacity: 0.8 }}>
                        Call Answered
                      </Typography>
                      <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                        18
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  padding: "24px",
                  backgroundColor: "#f43f5e",
                  color: "white",
                }}
              >
                <CardContent>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <div
                        style={{
                          padding: "8px",
                          backgroundColor: "rgba(255, 255, 255, 0.2)",
                          borderRadius: "50%",
                        }}
                      >
                        <PhoneMissed
                          style={{ width: "24px", height: "24px" }}
                        />
                      </div>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" sx={{ opacity: 0.8 }}>
                        Missed Call
                      </Typography>
                      <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                        2
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Search Section */}
          <Card sx={{ padding: "24px", marginTop: "32px" }}>
            <div>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "#1e1b4b" }}
              >
                Search Customer Detail
              </Typography>
              <div style={{ marginTop: "24px", maxWidth: "400px" }}>
                <div style={{ marginBottom: "16px" }}>
                  <Typography variant="body1" color="textSecondary">
                    Enter Caller Number <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="caller-number"
                    placeholder="Enter Caller Number"
                    variant="outlined"
                    fullWidth
                    sx={{
                      padding: "8px",
                      marginTop: "8px",
                      borderRadius: "8px",
                      backgroundColor: "#f5f5f5",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px",
                        borderColor: "#465ff166",
                        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      },
                    }}
                  />
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{
                    padding: "10px",
                    backgroundColor: "#1e1b4b",
                    "&:hover": {
                      backgroundColor: "#2e2a5b",
                    },
                  }}
                >
                  Search
                </Button>
              </div>
            </div>
          </Card>
        </Box>
      </Box>

      <Box></Box>
    </Box>
  );
}

export default AdminDashboard;
