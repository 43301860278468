import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import JusttawkBg from "../../../assets/justtawk-bg.png";
import JusttawkLogo from "../../../assets/Justtawk-01.png";

const Loading = () => {
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      navigate("/qr");
    }, 2000);
  }, []);

  return (
    <Box>
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        sx={{
          height: {
            xs: "50vh", // Use 50vh for mobile, it ensures it's more flexible
            md: "100vh", // Full height on larger screens
          },
          backgroundImage: {
            xs: `url(${JusttawkBg})`,
          },
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) => t.palette.primary.main,
          backgroundSize: "cover", // Ensures the image covers the entire area
          backgroundPosition: "center",
          position: "relative",
          color: "white",
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: { xs: "20px", md: "100px 0" }, // Adjust padding for mobile view
          mt: {
            xs: "-100px",
            md: "0px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: {
                xs: 400,
                md: 900,
              },
              fontSize: {
                xs: "1.1rem",
                md: "1.8rem",
              },
              mt: {
                xs: "50px",
                md: "0px",
              },
              whiteSpace: "nowrap",
            }}
          >
            Welcome to{" "}
            <Box
              sx={{
                display: { xs: "flex", md: "none" }, // Show image on mobile, hide on md and larger
                justifyContent: "center",
                alignItems: "center",
                p: "7px 0",
              }}
            >
              <img
                src={JusttawkLogo}
                alt="Justtawk Logo"
                style={{
                  height: "auto", // Keep aspect ratio intact
                  maxWidth: "100%", // Ensure the image does not exceed container width
                  width: "220px", // Set a fixed width for the image
                }}
              />
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "inline" }, // Show text on md and larger
              }}
            >
              Justtawk
            </Box>
          </Typography>

          <Typography
            sx={{
              fontWeight: {
                xs: 400,
                md: 100,
              },
              fontSize: "1rem",
            }}
          >
            Your Gateway to Effortless Call Center.
          </Typography>
        </Box>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: {
            xs: "60vh",
            md: "100vh",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: "1.8rem",
              md: "2rem",
            },
            textAlign: "center",
            lineHeight: {
              xs: "35px",
              md: "60px",
            },
            fontWeight: 900,
            p: "0 50px",
          }}
        >
          Please wait we are setting up your call center ...
        </Typography>
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: 100,
            color: {
              xs: "black",
              md: "grey",
            },
            textAlign: "center",
            mt: {
              xs: 2,
              md: 0,
            },
            p: "0 50px",
          }}
        >
          We are collecting your inputs to feed AI on future steps
        </Typography>
        <Box
          sx={{
            width: {
              xs: "70%",
              md: "50%",
            },
            mt: "50px",
          }}
        >
          <LinearProgress variant="indeterminate" value={progress} />
        </Box>
      </Box>
    </Box>
  );
};

export default Loading;
