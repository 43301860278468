import { createBrowserRouter } from "react-router-dom";
import Layout from "../layout";
import MainForm from "../view/form";
import Number from "../view/form/section/Number";
import Loading from "../view/form/section/Loading";
import Qr from "../view/form/section/Qr";
import Faq from "../view/faq";
import DashBoard from "../view/dashboard";
import MultiStepForm from "../view/form/section/MultiStepForm";
import Login from "../view/login/Login";
import Otp from "../view/login/Otp";
import SaaS from "../view/form/faq/saas/Saas";
import PricingPlans from "../view/pricing";
import AdminDashboard from "../view/adminDashboard";
import AdminLogin from "../view/login/AdminLogin";
import ECommerce from "../view/form/faq/ECommerce/ECommerce";
import Education from "../view/form/faq/Education/Education";
import HealthCare from "../view/form/faq/HealthCare/HealthCare";
import Software from "../view/form/faq/Software/Software";
import Startup from "../view/form/faq/Startup/Startup";
import Telecommunications from "../view/form/faq/Telecommunications/Telecommunications";
import Travel from "../view/form/faq/Travel/Travel";
import CallLogs from "../view/callLogs";
import ProtectedRoute from "./ProtectedRoute";
import Other from "../view/form/faq/Others/Other";
import BillDetails from "../view/billDetails/BillDetails";
import CheckOut from "../view/checkout";
import ChashfreeRes from "../view/checkout/ChashfreeRes";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/form",
    element: <ProtectedRoute element={<MultiStepForm />} />,
  },
  {
    path: "/number",
    element: <ProtectedRoute element={<Number />} />,
  },
  {
    path: "/loading",
    element: <ProtectedRoute element={<Loading />} />,
  },
  {
    path: "/qr",
    element: <ProtectedRoute element={<Qr />} />,
  },
  {
    path: "/saas",
    element: <ProtectedRoute element={<SaaS />} />,
  },
  {
    path: "/ECommerce",
    element: <ProtectedRoute element={<ECommerce />} />,
  },
  {
    path: "/Education",
    element: <ProtectedRoute element={<Education />} />,
  },
  {
    path: "/HealthCare",
    element: <ProtectedRoute element={<HealthCare />} />,
  },
  {
    path: "/Software",
    element: <ProtectedRoute element={<Software />} />,
  },
  {
    path: "/Startup",
    element: <ProtectedRoute element={<Startup />} />,
  },
  {
    path: "/Telecommunications",
    element: <ProtectedRoute element={<Telecommunications />} />,
  },
  {
    path: "/Travel",
    element: <ProtectedRoute element={<Travel />} />,
  },
  {
    path: "/Other",
    element: <ProtectedRoute element={<Other />} />,
  },
  {
    path: "/dashboard",
    element: <ProtectedRoute element={<DashBoard />} />,
  },
  {
    path: "/admin-dashboard",
    element: <ProtectedRoute element={<AdminDashboard />} />,
  },
  {
    path: "/faq",
    element: <ProtectedRoute element={<Faq />} />,
  },
  {
    path: "/Pricing",
    element: <ProtectedRoute element={<PricingPlans />} />,
  },
  {
    path: "/bill-details/:id",
    element: <ProtectedRoute element={<BillDetails />} />,
  },
  {
    path: "/checkout/",
    element: <ProtectedRoute element={<CheckOut />} />,
  },
  {
    path: "/checkout/:id",
    element: <ProtectedRoute element={<ChashfreeRes />} />,
  },
  {
    path: "/Call-Log",
    element: <ProtectedRoute element={<CallLogs />} />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/admin-login",
    element: <AdminLogin />,
  },
  {
    path: "/otp",
    element: <Otp />,
  },
]);

export default router;
