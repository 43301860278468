import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import Logo from "../../../../assets/logo.png";
import { useSpring, animated } from "react-spring";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import NorthIcon from "@mui/icons-material/North";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import TopArrow from "../../../../assets/Group.png";
import axios from "axios";
import lightbulb from "../../../../assets/lightbulb.png";
import yelloBulb from "../../../../assets/lightbulb1.png";

const validationSchema = Yup.object({
  question1: Yup.mixed().required("field is required"), // Making companyName required
  question2: Yup.mixed().required("field is required"), // Making industryType required
  question3: Yup.mixed().required("field is required"), // Making name required
  question4: Yup.mixed().required("field is required"), // Making name required
  question5: Yup.mixed().required("field is required"), // Making name required
});

const steps = [
  {
    title: "FAQ",
    description: "What are your delivery timelines?",
    placeholder: "Enter OTP",
  },
  {
    title: "FAQ",
    description: "How do I track my order?",
    placeholder: "Enter OTP",
  },
  {
    title: "FAQ",
    description: "Do you offer gift-wrapping services?",
    placeholder: "Enter OTP",
  },
  {
    title: "FAQ",
    description: "Can I change my delivery address after placing an order?",
    placeholder: "Enter OTP",
  },
  {
    title: "FAQ",
    description: "Do you offer cash on delivery (COD)?",
    placeholder: "Enter OTP",
  },
];

const ECommerceMobile = ({ nextStep }) => {
  const navigate = useNavigate();
  const sections = useRef([]);
  const [currentStep, setCurrentStep] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);

  const scrollToSection = (index) => {
    sections.current[index]?.scrollIntoView({ behavior: "smooth" });
  };

  const uuid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");

  const addFaqDetails = async (data) => {
    const access_token = token;

    try {
      const response = await axios.post(
        `https://uae.justtawk.com/index.php/api/Onboarding/Faq/${uuid}`,
        data,
        {
          headers: {
            Authorization: `${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      navigate("/number");
      console.log("Response:", response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const formik = useFormik({
    initialValues: {
      question1: "",
      question2: "",
      question3: "",
      question4: "",
      question5: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      const data = {
        "What are your delivery timelines?": values.question1,
        "How do I track my order?": values.question2,
        "Do you offer gift-wrapping services?": values.question3,
        "Can I change my delivery address after placing an order?":
          values.question4,
        "Do you offer cash on delivery (COD)?": values.question5,
      };
      addFaqDetails(data);
    },
  });

  const handleNext = async () => {
    const stepFields = {
      0: ["question1"],
      1: ["question2"],
      2: ["question3"],
      3: ["question4"],
      4: ["question5"],
    };

    const currentFields = stepFields[currentStep];

    formik.setTouched(
      currentFields.reduce((acc, field) => ({ ...acc, [field]: true }), {})
    );

    const errors = await formik.validateForm();

    const hasErrors = currentFields.some((field) => errors[field]);

    if (!hasErrors) {
      if (currentStep < steps.length - 1) {
        setCurrentStep((prev) => {
          const nextStep = prev + 1;
          scrollToSection(nextStep);
          return nextStep;
        });
        setActiveStep((prev) => prev + 1);
      } else {
        formik.handleSubmit();
      }
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => {
        const prevStep = prev - 1;
        scrollToSection(prevStep);
        setActiveStep((prev) => prev - 1);
        return prevStep;
      });
    }
  };

  const fade = useSpring({ opacity: 1, from: { opacity: 0 } });

  const handleCheckboxChange = (event, question) => {
    const { checked } = event.target;
    const defaultResponses = {
      question1:
        "Standard delivery takes 3-5 business days; express delivery takes 1-2 days.",
      question2:
        "You can track your order using the tracking link sent to your email or on our website.",
      question3:
        "Yes, we offer gift-wrapping at checkout for an additional charge.",
      question4:
        "	Yes, you can change it within 24 hours by contacting customer care.",
      question5: "Yes, COD is available in select locations.",
    };
    formik.setFieldValue(question, checked ? defaultResponses[question] : "");
  };

  // Initial state for each lightbulb
  const [bulbStates, setBulbStates] = useState({
    1: currentStep === 0 ? lightbulb : yelloBulb,
    2: currentStep === 1 ? yelloBulb : lightbulb,
    3: currentStep === 2 ? yelloBulb : lightbulb,
    4: currentStep === 3 ? yelloBulb : lightbulb,
    5: currentStep === 4 ? yelloBulb : lightbulb,
  });

  // Predefined answers for each bulb
   const bulbAnswers = {
    1: "Standard delivery takes 3-5 business days; express delivery takes 1-2 days.",
    2: "You can track your order using the tracking link sent to your email or on our website.",
    3: "Yes, we offer gift-wrapping at checkout for an additional charge.",
    4: "Yes, you can change it within 24 hours by contacting customer care.",
    5: "Yes, COD is available in select locations.",
  };
  // Handle bulb click
  const handleBulbClick = (bulbId) => {
    setBulbStates((prevState) => {
      const newState = { ...prevState };
      const isYellow = prevState[bulbId] === yelloBulb;

      // Toggle bulb state
      newState[bulbId] = isYellow ? lightbulb : yelloBulb;

      // Update formik field value based on state
      const questionKey = `question${bulbId}`;
      formik.setFieldValue(questionKey, isYellow ? "" : bulbAnswers[bulbId]);

      return newState;
    });
  };
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Grid container>
        <Grid item md={9.5} xs={12}>
          <Box>
            <form onSubmit={formik.handleSubmit}>
              <Grid
                container
                sx={{
                  mt: {
                    xs: "0px",
                    md: "100px",
                  },
                  p: "0 20px",
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={9.5}
                  sx={{ display: { xs: "initial", md: "none" } }}
                >
                  <Box>
                    <Box
                      sx={{
                        // height: { xs: "27vh", md: "36vh" },
                        overflow: "hidden",
                      }}
                    >
                      <Box
                        sx={{
                          width: { xs: "100%", md: "50%" },
                        }}
                      >
                        <animated.div style={fade}>
                          {/* Step 1 */}
                          {currentStep === 0 && (
                            <div ref={(el) => (sections.current[0] = el)}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color:
                                      currentStep !== 0 ? "lightgrey" : "black",
                                    opacity: currentStep !== 0 ? 0.5 : 1,
                                  }}
                                >
                                  What are your delivery timelines?
                                </Typography>
                                <Box sx={{ mr: "0px" }}>
                                  <Tooltip title="Suggestion" arrow>
                                    <img
                                      className="lightbulb-image"
                                      src={bulbStates[1]}
                                      style={{
                                        width: "30px",
                                        cursor: "pointer",
                                      }}
                                      alt="Suggestion"
                                      onClick={() => handleBulbClick(1)}
                                    />
                                  </Tooltip>
                                </Box>
                              </Box>
                              <TextField
                                name="question1"
                                id="question1"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                rows={3}
                                multiline
                                value={formik.values.question1}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={currentStep !== 0}
                                error={
                                  formik.touched.question1 &&
                                  Boolean(formik.errors.question1)
                                }
                                helperText={
                                  formik.touched.question1 &&
                                  formik.errors.question1
                                    ? formik.errors.question1
                                    : ""
                                }
                                sx={{ opacity: currentStep !== 0 ? 0.3 : 1 }}
                              />
                            </div>
                          )}

                          {/* Step 2 */}
                          {currentStep === 1 && (
                            <div ref={(el) => (sections.current[1] = el)}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color:
                                      currentStep !== 1 ? "lightgrey" : "black",
                                    opacity: currentStep !== 1 ? 0.5 : 1,
                                  }}
                                >
                                  How do I track my order?
                                </Typography>
                                <Box sx={{ mr: "0px" }}>
                                  <Tooltip title="Suggestion" arrow>
                                    <img
                                      className="lightbulb-image"
                                      src={bulbStates[2]}
                                      style={{
                                        width: "30px",
                                        cursor: "pointer",
                                      }}
                                      alt="Suggestion"
                                      onClick={() => handleBulbClick(2)}
                                    />
                                  </Tooltip>
                                </Box>
                              </Box>

                              <TextField
                                sx={{ opacity: currentStep !== 1 ? 0.3 : 1 }}
                                name="question2"
                                id="question2"
                                value={formik.values.question2}
                                onChange={formik.handleChange}
                                fullWidth
                                rows={3}
                                multiline
                                variant="outlined"
                                margin="normal"
                                disabled={currentStep !== 1}
                                error={
                                  formik.touched.question2 &&
                                  Boolean(formik.errors.question2)
                                }
                                helperText={
                                  formik.touched.question2 &&
                                  formik.errors.question2
                                    ? formik.errors.question2
                                    : ""
                                }
                              />
                            </div>
                          )}

                          {/* Step 3 */}
                          {currentStep === 2 && (
                            <div ref={(el) => (sections.current[2] = el)}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color:
                                      currentStep !== 2 ? "lightgrey" : "black",
                                    opacity: currentStep !== 2 ? 0.5 : 1,
                                  }}
                                >
                                  Do you offer gift-wrapping services?
                                </Typography>
                                <Box sx={{ mr: "0px" }}>
                                  <Tooltip title="Suggestion" arrow>
                                    <img
                                      className="lightbulb-image"
                                      src={bulbStates[3]}
                                      style={{
                                        width: "30px",
                                        cursor: "pointer",
                                      }}
                                      alt="Suggestion"
                                      onClick={() => handleBulbClick(3)}
                                    />
                                  </Tooltip>
                                </Box>
                              </Box>

                              <TextField
                                sx={{ opacity: currentStep !== 2 ? 0.3 : 1 }}
                                id="question3"
                                name="question3"
                                value={formik.values.question3}
                                onChange={formik.handleChange}
                                fullWidth
                                rows={3}
                                multiline
                                variant="outlined"
                                margin="normal"
                                disabled={currentStep !== 2}
                                error={
                                  formik.touched.question3 &&
                                  Boolean(formik.errors.question3)
                                }
                                helperText={
                                  formik.touched.question3 &&
                                  formik.errors.question3
                                    ? formik.errors.question3
                                    : ""
                                }
                              />
                            </div>
                          )}

                          {/* Step 4 */}
                          {currentStep === 3 && (
                            <div ref={(el) => (sections.current[3] = el)}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color:
                                      currentStep !== 3 ? "lightgrey" : "black",
                                  }}
                                >
                                  Can I change my delivery address after placing
                                  an order?
                                </Typography>
                                <Box sx={{ mr: "0px" }}>
                                  <Tooltip title="Suggestion" arrow>
                                    <img
                                      className="lightbulb-image"
                                      src={bulbStates[4]}
                                      style={{
                                        width: "30px",
                                        cursor: "pointer",
                                      }}
                                      alt="Suggestion"
                                      onClick={() => handleBulbClick(4)}
                                    />
                                  </Tooltip>
                                </Box>
                              </Box>

                              <TextField
                                id="question4"
                                name="question4"
                                value={formik.values.question4}
                                onChange={formik.handleChange}
                                fullWidth
                                rows={3}
                                multiline
                                variant="outlined"
                                margin="normal"
                                disabled={currentStep !== 3}
                                error={
                                  formik.touched.question4 &&
                                  Boolean(formik.errors.question4)
                                }
                                helperText={
                                  formik.touched.question4 &&
                                  formik.errors.question4
                                    ? formik.errors.question4
                                    : ""
                                }
                              />
                            </div>
                          )}

                          {/* Step 5 */}
                          {currentStep === 4 && (
                            <div ref={(el) => (sections.current[4] = el)}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color:
                                      currentStep !== 4 ? "lightgrey" : "black",
                                  }}
                                >
                                  Do you offer cash on delivery (COD)?
                                </Typography>
                                <Box sx={{ mr: "0px" }}>
                                  <Tooltip title="Suggestion" arrow>
                                    <img
                                      className="lightbulb-image"
                                      src={bulbStates[5]}
                                      style={{
                                        width: "30px",
                                        cursor: "pointer",
                                      }}
                                      alt="Suggestion"
                                      onClick={() => handleBulbClick(5)}
                                    />
                                  </Tooltip>
                                </Box>
                              </Box>

                              <TextField
                                id="question5"
                                name="question5"
                                value={formik.values.question5}
                                onChange={formik.handleChange}
                                fullWidth
                                rows={3}
                                multiline
                                variant="outlined"
                                margin="normal"
                                disabled={currentStep !== 4}
                                error={
                                  formik.touched.question5 &&
                                  Boolean(formik.errors.question5)
                                }
                                helperText={
                                  formik.touched.question5 &&
                                  formik.errors.question5
                                    ? formik.errors.question5
                                    : ""
                                }
                              />
                            </div>
                          )}
                        </animated.div>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2}
                  sx={{
                    ml: {
                      xs: "0px",
                      md: "30px",
                    },
                    p: {
                      xs: "20px 0 50px 0",
                      md: "0 0 0px 0",
                    },
                    display: {
                      xs: "flex",
                      md: "initial",
                    },
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    color="primary"
                    onClick={handleBack}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <img src={TopArrow} />
                  </Box>
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={() => {
                      handleNext();
                    }}
                    sx={{
                      whiteSpace: "nowrap",
                      textTransform: "capitalize",
                      mt: {
                        xs: "0px",
                        md: "15px",
                      },
                      ml: {
                        xs: "30px",
                        md: "0",
                      },
                    }}
                  >
                    Save & go next
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ECommerceMobile;
