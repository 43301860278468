import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

const Timmer = () => {
  const [timer, setTimer] = useState("");
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const initializeTimer = () => {
      const storedStartDate = localStorage.getItem("startDate");
      if (!storedStartDate) {
        const now = new Date().toISOString();
        localStorage.setItem("startDate", now);
        return new Date(now).getTime();
      }
      return new Date(storedStartDate).getTime();
    };

    const startDate = initializeTimer();
    const targetDuration = 70 * 60 * 60 * 1000; // 70 hours in milliseconds

    const calculateRemainingTime = () => {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - startDate; // Time elapsed since start
      const remainingTime = targetDuration - elapsedTime;
      if (remainingTime <= 0) {
        setIsExpired(true);
        return "00 hrs : 00 mins : 00 secs"; // Timer has expired
      }
      const hours = Math.floor(remainingTime / (1000 * 60 * 60));
      const minutes = Math.floor(
        (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}:${String(seconds).padStart(2, "0")}`;
    };

    setTimer(calculateRemainingTime());
    const interval = setInterval(() => {
      setTimer(calculateRemainingTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Typography
        sx={{
          fontSize: {
            xs: "1rem",
            md: "1rem",
          },
          textAlign: "center",
          background: isExpired
            ? "linear-gradient(90deg, #ff7b7b, #ff4c4c)"
            : "linear-gradient(90deg, #6a82fb, #fc5c7d)",
          color: "white",
          borderRadius: 2,
          p: "5px 20px",
          fontWeight: 400,
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        {isExpired
          ? "🚨 Subscribe Now 🚨"
          : `⏳ Demo Plan Expires In: ${timer}`}
      </Typography>
    </div>
  );
};

export default Timmer;
