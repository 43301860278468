import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  IconButton,
  Modal,
  Button,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddFaq from "./AddFaq";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup"; // Import Yup for validation

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

// Formik and Yup for form validation
const validationSchema = Yup.object({
  question: Yup.string()
    .required("Question is required")
    .min(5, "Question should be at least 5 characters long"),
  answer: Yup.string()
    .required("Answer is required")
    .min(10, "Answer should be at least 10 characters long"),
});

const AccordionFaq = () => {
  const [faqData, setFaqData] = useState([]);
  const [open, setOpen] = useState(false);
  const [editingFaq, setEditingFaq] = useState(null);
  const uid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");

  useEffect(() => {
    getFaq();
  }, []);

  const getFaq = async () => {
    try {
      const res = await axios.get(
        `https://uae.justtawk.com/index.php/api/Onboarding/FaqByUser/${uid}`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setFaqData(res?.data?.data);
      console.log("FAQ Response:", res.data.data);
    } catch (error) {
      console.error("Error fetching FAQ:", error);
    }
  };

  const handleOpen = (faq) => {
    setEditingFaq(faq);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingFaq(null);
  };

  const formik = useFormik({
    initialValues: {
      question: editingFaq?.faq_title || "",
      answer: editingFaq?.faq_answer || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true, // Important to reinitialize when editing a FAQ
    onSubmit: (values) => {
      handleEditFaq({
        ...editingFaq,
        faq_title: values.question,
        faq_answer: values.answer,
      });
    },
  });

  const handleEditFaq = async (updatedFaq) => {
    const access_token = token;
    const data = {
      ques: updatedFaq.faq_title,
      ans: updatedFaq.faq_answer,
    };
    try {
      const res = await axios.put(
        `https://uae.justtawk.com/index.php/api/Onboarding/Faq/${updatedFaq.faq_id}`,
        data,
        {
          headers: {
            Authorization: `${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(res);

      getFaq();
      handleClose();
    } catch (error) {
      console.error("Error updating FAQ:", error);
    }
  };

  return (
    <Box>
      <AddFaq getFaq={getFaq} />
      {faqData.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="h6">{faq?.faq_title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq?.faq_answer}</Typography>
            <Box sx={{ display: "flex", mt: "20px" }}>
              <Button
                sx={{
                  border: "1px solid #0C247B",
                  color: "#0C247B",
                }}
                onClick={() => handleOpen(faq)}
                variant="outlined"
              >
                Edit
              </Button>
              {/* <Button
                sx={{ ml: "50px", border: "1px solid red", color: "red" }}
                onClick={() => handleOpen(faq)}
                variant="outlined"
              >
                Delete
              </Button> */}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}

      {/* Modal for Editing FAQ */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style, width: 400 }}>
          <Typography variant="h6">Edit FAQ</Typography>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              label="Question"
              name="question"
              fullWidth
              variant="outlined"
              margin="normal"
              value={formik.values.question}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.question && Boolean(formik.errors.question)}
              helperText={formik.touched.question && formik.errors.question}
              required
            />
            <TextField
              label="Answer"
              name="answer"
              fullWidth
              variant="outlined"
              margin="normal"
              multiline
              rows={4}
              value={formik.values.answer}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.answer && Boolean(formik.errors.answer)}
              helperText={formik.touched.answer && formik.errors.answer}
              required
            />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="contained" color="primary" type="submit">
                Save Changes
              </Button>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default AccordionFaq;
