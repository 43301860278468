import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import PhoneMissedIcon from "@mui/icons-material/PhoneMissed";

const CardsWidgets = ({ widgetData }) => {
  return (
    <Box sx={{ pt: "30px" }}>
      <Grid container spacing={2}>
        {/* Total Calls Card */}
        <Grid item xs={12} sm={6} md={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: 2,
              bgcolor: "#626BE2",
              boxShadow: "rgba(0, 0, 0, 0.22) 0px 1px 1px",
              p: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: 36,
                pr: 2,
              }}
            >
              <SmartphoneIcon style={{ color: "white" }} />
            </Box>
            <Box>
              <Typography
                sx={{ fontWeight: 550, color: "white", fontSize: "1rem" }}
              >
                Total Call
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "white" }}
              >
                {widgetData[0]?.total || 0}
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* Call Answered Card */}
        <Grid item xs={12} sm={6} md={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: 2,
              bgcolor: "#3CA5F4",
              boxShadow: "rgba(0, 0, 0, 0.22) 0px 1px 1px",
              p: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: 36,
                pr: 2,
              }}
            >
              <PhoneInTalkIcon style={{ color: "white" }} />
            </Box>
            <Box>
              <Typography
                sx={{ fontWeight: 550, color: "white", fontSize: "1rem" }}
              >
                Call Answered
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "white" }}
              >
                {widgetData[0]?.Answered_Call || 0}
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* Missed Call Card */}
        <Grid item xs={12} sm={6} md={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: 2,
              bgcolor: "#F1526D",
              boxShadow: "rgba(0, 0, 0, 0.22) 0px 1px 1px",
              p: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: 36,
                pr: 2,
              }}
            >
              <PhoneMissedIcon style={{ color: "white" }} />
            </Box>
            <Box>
              <Typography
                sx={{ fontWeight: 550, color: "white", fontSize: "1rem" }}
              >
                Missed Call
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "white" }}
              >
                {widgetData[0]?.Missed_Call || 0}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CardsWidgets;
