import React, { useEffect } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const CheckOut = ({ resDetails, handleSubmit }) => {
  const userOrderAmount = localStorage.getItem("orderAmount");
  const userName = localStorage.getItem("username");
  const userPhoneNo = localStorage.getItem("mobileNo");
  const paymentSession = localStorage.getItem("paymentSessionId");
  const navigate = useNavigate();

  useEffect(() => {
    // Dynamically load the Cashfree SDK script
    const script = document.createElement("script");
    script.src = "https://sdk.cashfree.com/js/v3/cashfree.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.Cashfree) {
        const cashfree = window.Cashfree({ mode: "production" });

        document.getElementById("renderBtn").addEventListener("click", () => {
          const paymentSessionId = paymentSession;

          cashfree.checkout({ paymentSessionId }).then((result) => {
            if (result.error) {
              console.error("Payment error:", result.error);
            } else if (result.paymentDetails) {
              console.log("Payment successful, verifying order status...");
            }
          });
        });
      } else {
        console.error("Cashfree SDK failed to load.");
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [paymentSession]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#ffffff",
          p: 4,
          borderRadius: 3,
          boxShadow: 4,
          width: "100%",
          maxWidth: "500px",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.5rem",
            color: "#333",
            fontWeight: 900,
            textAlign: "center",
            marginBottom: "30px",
          }}
          gutterBottom
        >
          Confirm Your Details
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 2,
              }}
            >
              <Typography
                sx={{ fontSize: "1rem", fontWeight: 600, color: "#666" }}
              >
                Name :
              </Typography>
              <Typography
                sx={{ fontSize: "1.2rem", fontWeight: 700, color: "#333" }}
              >
                {userName}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 2,
              }}
            >
              <Typography
                sx={{ fontSize: "1rem", fontWeight: 600, color: "#666" }}
              >
                Phone :
              </Typography>
              <Typography
                sx={{ fontSize: "1.2rem", fontWeight: 700, color: "#333" }}
              >
                {userPhoneNo}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 2,
              }}
            >
              <Typography
                sx={{ fontSize: "1rem", fontWeight: 600, color: "#666" }}
              >
                Pay Amount :
              </Typography>
              <Typography
                sx={{ fontSize: "1.5rem", fontWeight: 700, color: "#27ae60" }}
              >
                ₹{userOrderAmount}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  padding: "10px 20px",
                  fontSize: "1rem",
                  fontWeight: "600",
                  backgroundColor: "#3498db",
                  "&:hover": {
                    backgroundColor: "#2980b9",
                  },
                }}
                onClick={() => {
                  navigate("/bill-details");
                }}
              >
                Back
              </Button>
              <Button
                id="renderBtn"
                variant="contained"
                color="primary"
                sx={{
                  padding: "10px 20px",
                  fontSize: "1rem",
                  fontWeight: "600",
                  backgroundColor: "#3498db",
                  "&:hover": {
                    backgroundColor: "#2980b9",
                  },
                }}
              >
                Confirm & Pay
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CheckOut;
