import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Container, Grid } from "@mui/material";
import { QRCodeSVG } from "qrcode.react"; // Import the QRCode component
import { useNavigate } from "react-router-dom";
import Confetti from "react-confetti";
import JusttawkLogo from "../../../assets/Justtawk-01.png";
import JusttawkBg from "../../../assets/justtawk-bg.png";

// Custom hook to handle window size
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

export default function Qr() {
  const navigate = useNavigate();
  const callCenterNumber = localStorage.getItem("selectedPhone");

  const { width, height } = useWindowSize();

  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 4300);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Box>
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        sx={{
          height: {
            xs: "50vh", // Use 50vh for mobile, it ensures it's more flexible
            md: "100vh", // Full height on larger screens
          },
          backgroundImage: {
            xs: `url(${JusttawkBg})`,
          },
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) => t.palette.primary.main,
          backgroundSize: "cover", // Ensures the image covers the entire area
          backgroundPosition: "center",
          position: "relative",
          color: "white",
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: { xs: "20px", md: "100px 0" }, // Adjust padding for mobile view
          mt: {
            xs: "-100px",
            md: "0px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: {
                xs: 400,
                md: 900,
              },
              fontSize: {
                xs: "1.1rem",
                md: "1.8rem",
              },
              mt: {
                xs: "50px",
                md: "0px",
              },
              whiteSpace: "nowrap",
            }}
          >
            Welcome to{" "}
            <Box
              sx={{
                display: { xs: "flex", md: "none" }, // Show image on mobile, hide on md and larger
                justifyContent: "center",
                alignItems: "center",
                p: "7px 0",
              }}
            >
              <img
                src={JusttawkLogo}
                alt="Justtawk Logo"
                style={{
                  height: "auto", // Keep aspect ratio intact
                  maxWidth: "100%", // Ensure the image does not exceed container width
                  width: "220px", // Set a fixed width for the image
                }}
              />
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "inline" }, // Show text on md and larger
              }}
            >
              Justtawk
            </Box>
          </Typography>

          <Typography
            sx={{
              fontWeight: {
                xs: 400,
                md: 100,
              },
              fontSize: "1rem",
            }}
          >
            Your Gateway to Effortless Call Center.
          </Typography>
        </Box>
      </Grid>
      {showConfetti && <Confetti width={width} height={height} />}
      <Box
        sx={{
          my: {
            xs: 3,
            md: 10,
          },
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: "1.8rem",
              md: "3rem",
            },
            fontWeight: 900,
            lineHeight: { xs: "35px", md: "50px" },
            p: "0 30px",
          }}
          component="h1"
        >
          Congratulation! Your Call Center is ready
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            mb: 4,
            fontSize: {
              xs: "1rem",
              md: "1.4rem",
            },
            fontWeight: 100,
            color: {
              xs: "black",
              md: "grey",
            },
            p: {
              xs: "0 50px",
              md: "0 200px",
            },
            lineHeight: {
              xs: "20px",
              md: "26px",
            },

            mt: {
              xs: 2,
              md: 1,
            },
          }}
        >
          Call on your customer care number and enjoy an exceptional experience
          tailored to your needs.
        </Typography>
        {/* Generate the QR code dynamically based on the call center number */}
        <Box
          sx={{
            m: {
              xs: " 0px 0",
              md: "50px 0",
            },
          }}
        >
          <QRCodeSVG value={callCenterNumber} size={150} />
        </Box>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            mt: 2,
            fontSize: {
              xs: "1.1rem",
              md: "1.5rem",
            },
          }}
        >
          Your Call Center number: <br />
          <a
            href={`tel:${callCenterNumber}`} // Use the tel: protocol to make it clickable
            style={{
              textDecoration: "none", // Remove the default underline
              color: "#1976d2", // Set the color to make it look like a link
              fontWeight: 900,
            }}
          >
            {callCenterNumber}
          </a>
        </Typography>
        <Typography
          variant="body2"
          sx={{
            mb: 4,
            color: "grey",
            fontWeight: 100,
            fontSize: {
              xs: "0.8rem",
              md: "0.9rem",
            },
            fontWeight: 100,
            color: {
              xs: "black",
              md: "grey",
            },
            p: {
              xs: "0 50px",
              md: "0 200px",
            },
            lineHeight: "20px",
            display: {
              xs: "none",
              md: "flex",
            },
            flexDirection: "column",
          }}
        >
          Scan the QR code with your mobile to connect with your call center,
          <br />
          or dial the customer care number above.
        </Typography>
        <Button
          variant="contained"
          size="large"
          sx={{
            textTransform: "capitalize",
            m: {
              xs: "10px",
              md: 0,
            },
          }}
          onClick={() => navigate("/dashboard")}
        >
          Go to Dashboard
        </Button>
      </Box>
    </Box>
  );
}
